<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.RESELLER')} (*)`"
      :placeholder="$t('COMMON.RESELLER')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <reseller-selector
        :reseller="pack.reseller.id"
        :filterable="true"
        :showAll="false"
        @resellerChanged="resellerChanged"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <image-selector
      :label="$t('COMMON.PICTURE')"
      :defaultImage="pack.picture"
      ressource_name="packages"
      :ressource_id="pack.id ? pack.id : 0"
      field="picture"
      @imageChanged="
        (file_url) => {
          pack.picture = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <gallery-selector
      :label="$t('COMMON.GALLERY')"
      :defaultGallery="pack.gallery"
      ressource_name="packages"
      :ressource_id="pack.id ? pack.id : 0"
      field="gallery"
      @galleryChanged="
        (gallery_urls) => {
          pack.gallery = gallery_urls;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="pack.name"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      type="textarea"
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="pack.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.PRICE')} (*)`"
      :placeholder="$t('COMMON.PRICE')"
      v-model="pack.price"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.price" />

    <base-input
      :label="`${$t('PACKAGES.FREQUENCY')} (*)`"
      :placeholder="$t('PACKAGES.FREQUENCY')"
      v-model="pack.frequency"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.frequency" />

    <base-input
      :label="`${$t('PACKAGES.MAXIMUM_USERS')} (*)`"
      :placeholder="$t('PACKAGES.MAXIMUM_USERS')"
      v-model="pack.maximum_users"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.maximum_users" />

    <base-input
      :label="`${$t('PACKAGES.DEFAULT_ROLE')} (*)`"
      :placeholder="$t('PACKAGES.DEFAULT_ROLE')"
    >
      <role-selector
        :disabled="!pack.reseller.id"
        :role="pack.default_role.id"
        :filterable="true"
        :showAll="false"
        :filterReseller="pack.reseller.id"
        @roleChanged="
          (roleId) => {
            pack.default_role.id = roleId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.default_role" />

    <taxes-selector
      :label="$t('COMMON.TAXES')"
      :taxes="pack.taxes"
      @taxesChanged="
        (taxes) => {
          pack.taxes = taxes;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.taxes" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ pack.id ? $t("PACKAGES.EDIT_PACKAGE") : $t("PACKAGES.ADD_PACKAGE") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import RoleSelector from "@/components/RoleSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    GallerySelector,
    TaxesSelector,
    RoleSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["packData", "formErrors", "loading"],

  data() {
    const currentUserReseller = this.$currentUser().reseller
      ? this.$currentUser().reseller.id
      : null;
    return {
      pack: {
        ...this.packData,
        ...(currentUserReseller
          ? { reseller: { type: "resellers", id: currentUserReseller } }
          : {}),
      },
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.$emit("packSubmitted", this.pack);
    },

    resellerChanged(resellerId) {
      this.pack.reseller.id = resellerId;
      this.pack.default_role.id = null;
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    packData(packData) {
      if (packData) {
        const currentUserReseller = this.$currentUser().reseller
          ? this.$currentUser().reseller.id
          : null;
        this.pack = {
          ...this.pack,
          ...cloneDeep(packData),
          ...(currentUserReseller
            ? { reseller: { type: "resellers", id: currentUserReseller } }
            : {}),
        };
      }
    },
  },
};
</script>
